import React, { FC, useState } from 'react';
import {
  FileSearchOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SignatureOutlined,
} from '@ant-design/icons';
import { googleLogout } from '@react-oauth/google';
import { Avatar, Button, Layout, Menu, MenuProps, theme } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'services/auth';
import { SEARCH_ROUTE, SIGNUPS_ROUTE } from '../../constants';

const { Header, Sider, Content } = Layout;

const HomePage: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { logout, loggedInUser } = useAuth();

  const { pathname: currentPath } = useLocation();
  const items: MenuProps['items'] = [
    {
      label: <Link to={SEARCH_ROUTE}>NPI Lookup</Link>,
      icon: <FileSearchOutlined />,
      key: SEARCH_ROUTE,
    },
    {
      label: <Link to={SIGNUPS_ROUTE}>New Sign Ups</Link>,
      icon: <SignatureOutlined />,
      key: SIGNUPS_ROUTE,
    },
  ];

  const selectedItemKey =
    items.find((item) => currentPath.split('/')[1] === (item?.key as string))
      ?.key || '';
  return (
    <Layout style={{ height: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div style={{ margin: '10px', padding: '5px' }}>
          <Avatar shape="square" size={48} src={loggedInUser.picture} />
          {collapsed ? null : (
            <>
              <span
                style={{
                  color: colorBgContainer,
                  margin: '0 5px',
                  top: '25px',
                  position: 'absolute',
                }}
              >
                {loggedInUser.given_name}
              </span>
              <span
                style={{
                  color: colorBgContainer,
                  margin: '0 5px',
                  top: '45px',
                  position: 'absolute',
                  fontSize: '10px',
                  wordBreak: 'break-all',
                }}
              >
                {loggedInUser.email}
              </span>
            </>
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          items={items}
          defaultSelectedKeys={[selectedItemKey as string]}
          // defaultSelectedKeys={['1']}
        />
        <div style={{ position: 'absolute', bottom: '24px', left: '24px' }}>
          <Button
            type="text"
            icon={<LogoutOutlined />}
            style={{ color: colorBgContainer }}
            onClick={() => {
              googleLogout();
              logout();
            }}
          >
            {collapsed ? null : 'Logout'}
          </Button>
        </div>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            //refactor
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            overflow: 'auto',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default HomePage;
