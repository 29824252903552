import React, { FC, useEffect, useState } from 'react';
import './DifferencesComponent.css';
import { Empty, DescriptionsProps, Descriptions, Table } from 'antd';
import { DifferencesData } from 'interfaces';

interface DifferencesComponentProps {
  data: DifferencesData;
}

const DifferencesComponent: FC<DifferencesComponentProps> = ({ data }) => {
  const { npiData, airtableData } = data;

  const renderEmpty = (text: string) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Empty description={text} />
      </div>
    );
  };

  const renderMatchedItems = () => {
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const matches = Object.keys(npiData).filter(
      (field) =>
        npiData[field]?.toLowerCase() === airtableData[field]?.toLowerCase()
    );

    useEffect(() => {
      setIsEmpty(matches.length === 0);
    }, [matches]);

    const matchedItems: DescriptionsProps['items'] = matches.map(
      (match, index) => ({
        key: index,
        label: match,
        labelStyle: { width: '20vw' },
        contentStyle: { width: '20vw' },
        children: <>{npiData[match]}</>,
      })
    );

    return (
      <>
        <h3>Matched Fields</h3>
        {isEmpty ? (
          renderEmpty('No Matched Items')
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Descriptions
              size="middle"
              bordered
              column={1}
              style={{
                width: '40vw',
                maxWidth: '400px',
                marginBottom: '10px',
              }}
              items={matchedItems}
            />
          </div>
        )}
      </>
    );
  };

  const renderDifferences = () => {
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const allowedFields = [
      'First Name',
      'Last Name',
      'State',
      'Address',
      'Personal Phone Number',
      'Taxonomy Code',
    ];
    const differences = Object.keys(npiData).filter(
      (field) =>
        npiData[field]?.toLowerCase() !== airtableData[field]?.toLowerCase()
    );
    useEffect(() => {
      setIsEmpty(differences.length === 0);
    }, [differences]);

    const dataSource = differences.flatMap((field, index) => {
      if (!allowedFields.includes(field)) return [];
      return {
        key: index,
        fieldName: field,
        npiValue: npiData[field],
        airtableValue: airtableData[field],
      };
    });

    const columns = [
      {
        title: 'Field',
        dataIndex: 'fieldName',
        key: 'fieldName',
      },
      {
        title: 'NPI Datasette',
        dataIndex: 'npiValue',
        width: 250,
        key: 'npiValue',
      },
      {
        title: 'Airtable',
        dataIndex: 'airtableValue',
        width: 250,
        key: 'airtableValue',
      },
    ];

    return (
      <>
        <h3>Differences</h3>
        {isEmpty ? (
          renderEmpty('No Differences')
        ) : (
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            bordered
          />
        )}
      </>
    );
  };

  return (
    <>
      {renderMatchedItems()}
      {renderDifferences()}
    </>
  );
};

export default DifferencesComponent;
