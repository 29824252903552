import React, { FC } from 'react';
import { Result } from 'antd';

interface ErrorProps {
  errorText?: React.ReactNode;
}

const Error: FC<ErrorProps> = ({ errorText }) => {
  return (
    <Result
      status="warning"
      title={errorText || 'There are some problems with your operation.'}
    />
  );
};

export default Error;
