import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  DatePicker,
  DatePickerProps,
  Descriptions,
  DescriptionsProps,
  Image,
  Select,
  Space,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import {
  deleteCertificateFromAirtable,
  setAirtableProviderLicenseDate,
  uploadFileURL,
} from 'services/api';
import { useProviderContext } from '../ProviderContext';

const { Title, Paragraph } = Typography;

const Step5: React.FC = () => {
  const { control, reset, getValues, watch, setValue } = useFormContext();
  const { providerMongoDBData, providerAirtableData } = useProviderContext();
  const stepFiveUploadsFromAirtable =
    providerAirtableData?.licenseCertificateCopy?.map((item) => ({
      uid: item.id,
      name: item.filename,
      thumbUrl: item.thumbnails?.large?.url,
    })) || [];

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>(
    stepFiveUploadsFromAirtable
  );

  useEffect(() => {
    reset({ ...getValues(), stepFiveUploads: fileList });
  }, [fileList]);

  const providerEmail =
    providerMongoDBData?.email ||
    providerAirtableData?.providerPersonalEmailId ||
    providerAirtableData?.deelEmailId ||
    providerAirtableData?.wellniteEmailId;

  const descriptionItems: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Provider Full Name',
      labelStyle: { width: '20vw' },
      contentStyle: { width: '20vw' },
      children: (
        <>
          {providerAirtableData.providerFullName || (
            <span style={{ color: 'red' }}>Name not provided</span>
          )}
        </>
      ),
    },
    {
      key: '2',
      label: 'Provider State License Number',
      children: (
        <>
          {providerAirtableData.providerStateLicenseNumber || (
            <span style={{ color: 'red' }}>License number not provided</span>
          )}
        </>
      ),
    },
    {
      key: '3',
      label: 'Provider License States',
      children: (
        <>
          {providerMongoDBData?.states?.join(', ') || (
            <span style={{ color: 'red' }}>License states not provided</span>
          )}
        </>
      ),
    },
    {
      key: '4',
      label: 'Provider License Type',
      children: (
        <>
          {providerAirtableData?.titleProviderPerformingSpecialtyProviderLicenseType?.join(
            ', '
          ) || <span style={{ color: 'red' }}>License type not provided</span>}
        </>
      ),
    },
  ];

  const handlePreview = async (file: UploadFile) => {
    if (!file.thumbUrl) {
      return;
    }
    setPreviewImage(file.thumbUrl);
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({
    file,
    fileList: newFileList,
  }) => {
    if (file.status === 'done') {
      newFileList[newFileList.length - 1].uid = file.response.fileId;
      setValue('stepFiveUploads', newFileList);
      message.success(`${file.name} file uploaded successfully`);
    } else if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }
    setFileList(newFileList);
  };

  const handleRemove: UploadProps['onRemove'] = async (file) => {
    const deleteFile = await deleteCertificateFromAirtable(
      providerEmail,
      file.uid
    );
    if (deleteFile.status === 204) {
      message.success('File deleted successfully');
      return true;
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const stepFiveLicenseEffectiveDate = watch('stepFiveLicenseEffectiveDate');
  const stepFiveLicenseExpirationDate = watch('stepFiveLicenseExpirationDate');

  const onEffectiveDateChange: DatePickerProps['onChange'] = async (date) => {
    setValue(
      'stepFiveLicenseEffectiveDate',
      date?.format('MMMM D, YYYY') || ''
    );
    await setAirtableProviderLicenseDate(
      providerEmail,
      'effective',
      date?.format('MMMM D, YYYY')
    );
  };

  const onExpirationDateChange: DatePickerProps['onChange'] = async (date) => {
    setValue(
      'stepFiveLicenseExpirationDate',
      date?.format('MMMM D, YYYY') || ''
    );
    await setAirtableProviderLicenseDate(
      providerEmail,
      'expiration',
      date?.format('MMMM D, YYYY')
    );
  };

  return (
    <>
      <Typography>
        <Descriptions
          title={<h4>Provider License Info</h4>}
          size="middle"
          bordered
          items={descriptionItems}
          column={1}
          style={{ width: '40vw', maxWidth: '600px' }}
        />
        <Title level={4}>Certificate Details</Title>
        <Upload
          action={uploadFileURL(providerEmail)}
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={handleRemove}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        {previewImage && (
          <Image
            wrapperStyle={{ display: 'none' }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(''),
            }}
            src={previewImage}
          />
        )}
        <Space direction="vertical" style={{ width: '100%' }}>
          <span>Effective Date: </span>
          <DatePicker
            onChange={onEffectiveDateChange}
            value={
              stepFiveLicenseEffectiveDate
                ? dayjs(stepFiveLicenseEffectiveDate, 'MMMM D, YYYY')
                : null
            }
          />
          <span>Expiration Date: </span>
          <DatePicker
            onChange={onExpirationDateChange}
            value={
              stepFiveLicenseExpirationDate
                ? dayjs(stepFiveLicenseExpirationDate, 'MMMM D, YYYY')
                : null
            }
          />
        </Space>
        <Title level={4}>Tips</Title>
        <Paragraph>
          <li>
            License Details: Use the practitioner&apos;s provided license number
            and full name to search for their license status, ensuring it&apos;s
            active and in good standing.
          </li>
        </Paragraph>
        <Paragraph>
          <li>
            Cross-Reference: Compare the details obtained from the state license
            board with the information provided by the practitioner, such as
            name, license number, and specialty, for consistency and accuracy.
          </li>
        </Paragraph>
      </Typography>
      <div className="center-step-actions">
        <FormItem
          name="stepFive"
          label={
            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
              How many data points are mismatching ?
            </p>
          }
          control={control}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select
            style={{ width: 80 }}
            options={[
              { value: 0, label: '0' },
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 5, label: '5' },
              { value: 6, label: '6' },
            ]}
          />
        </FormItem>
      </div>
    </>
  );
};

export default Step5;
