import React from 'react';
import { Descriptions, DescriptionsProps, Select, Typography } from 'antd';
import { useFormContext } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { useProviderContext } from '../ProviderContext';

const { Title, Paragraph } = Typography;

const Step2: React.FC = () => {
  const { control } = useFormContext();
  const { providerAirtableData } = useProviderContext();

  const providerLegalActions: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Legal Actions',
      labelStyle: { width: '140px' },
      children: <>{providerAirtableData?.legalActions}</>,
    },
  ];

  return (
    <>
      <Typography>
        <Descriptions
          size="middle"
          bordered
          column={1}
          style={{
            // width: '40vw',
            // maxWidth: '400px',
            marginBottom: '10px',
          }}
          items={providerLegalActions}
        />
        <Title level={4}>Tips</Title>

        <Paragraph>
          <li>
            Data Matching: Cross-reference each provided detail against the NPI
            database, focusing on exact matches for names, specialties, and
            locations.
          </li>
        </Paragraph>

        <Paragraph>
          <li>
            Anomaly Identification: Be vigilant for any anomalies or
            irregularities between provided information and NPI data, such as
            mismatched addresses or practice details.
          </li>
        </Paragraph>
      </Typography>
      <FormItem
        name="stepTwo"
        label={
          <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
            Is the provider clean?
          </p>
        }
        control={control}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Select
          style={{ width: 80 }}
          options={[
            { value: true, label: 'yes' },
            { value: false, label: 'no' },
          ]}
        />
      </FormItem>
    </>
  );
};

export default Step2;
