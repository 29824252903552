import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { List, Result, ResultProps } from 'antd';
import { useFormContext } from 'react-hook-form';

const Step6: React.FC = () => {
  const { getValues } = useFormContext();
  const getResult = (): ResultProps => {
    const redFlags = getValues('redFlags');
    if (redFlags) {
      return {
        status: 'error',
        title: 'Red Flags identified',
        subTitle: `Number of Red Flags: ${redFlags}`,
      };
    }
    return { status: 'success', title: 'No red flags identified' };
  };
  const { stepOne, stepThree, stepFour, stepFive } = getValues();
  const stepsRedFlags = [];

  if (!stepOne) {
    stepsRedFlags.push({
      step: 'Step 1',
      redFlagsCount: 1,
    });
  }

  if (stepThree) {
    stepsRedFlags.push({
      step: 'Step 3',
      redFlagsCount: stepThree,
    });
  }

  if (stepFour) {
    stepsRedFlags.push({
      step: 'Step 4',
      redFlagsCount: stepFour,
    });
  }

  if (stepFive) {
    stepsRedFlags.push({
      step: 'Step 5',
      redFlagsCount: stepFive,
    });
  }

  return (
    <>
      <Result {...getResult()} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <List
          itemLayout="horizontal"
          dataSource={stepsRedFlags}
          style={{ width: '400px' }}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <>
                    <ExclamationCircleOutlined /> <span>{item.step}</span>
                  </>
                }
                description={`${item.redFlagsCount} Red flags`}
              />
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

export default Step6;
