import React, { FC } from 'react';
import HomePage from 'components/HomePage';
import Login from 'components/Login';
import NewSignUps from 'components/NewSignUps';
import NotFound from 'components/NotFound';
import SearchComponent from 'components/SearchComponent';
import VerificationForm from 'components/VerificationForm';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import {
  SEARCH_ROUTE,
  SIGNUPS_ROUTE,
  SIGNUPS_VERIFICATION_FORM_ROUTE,
} from '../constants';

const AppRouter: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute Component={HomePage} />}>
          <Route path={SEARCH_ROUTE} element={<SearchComponent />} />
          <Route path={SIGNUPS_ROUTE} element={<NewSignUps />} />
          <Route
            path={SIGNUPS_VERIFICATION_FORM_ROUTE}
            element={<VerificationForm />}
          />
        </Route>
        <Route path="/*" element={<PrivateRoute Component={NotFound} />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
