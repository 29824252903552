import React from 'react';
import AppRouter from 'routes/Router';
import { AuthProvider } from 'services/auth';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  );
}

export default App;
