import React, { createContext, useContext, useState } from 'react';
import { logoutGoogleUser } from './api';

interface AuthContextProps {
  isAuthenticated: boolean;
  loggedInUser: LoggedInUserType;
  login: (user: LoggedInUserType) => void;
  logout: () => void;
}

interface LoggedInUserType {
  name: string;
  given_name: string;
  email: string;
  picture: string;
}
const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUserType>(
    {} as LoggedInUserType
  );

  const login = (user: LoggedInUserType) => {
    setLoggedInUser(user);
    setIsAuthenticated(true);
  };

  const logout = async () => {
    await logoutGoogleUser();
    setLoggedInUser({} as LoggedInUserType);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, loggedInUser, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
