export const statusFieldFilters = [
  { text: 'Approved', value: 'approved' },
  { text: 'Not Started', value: 'not started' },
  { text: 'Ongoing', value: 'ongoing' },
  { text: 'Rejected', value: 'rejected' },
];

export const countryFieldFilters = [
  { text: 'Australia', value: 'Australia' },
  { text: 'Canada', value: 'Canada' },
  { text: 'Chile', value: 'Chile' },
  { text: 'Colombia', value: 'Colombia' },
  { text: 'Mexico', value: 'Mexico' },
  { text: 'New Zealand', value: 'New Zealand' },
  { text: 'South Africa', value: 'South Africa' },
  { text: 'Spain', value: 'Spain' },
  { text: 'United States', value: 'United States' },
];
