import React, { createContext, useContext } from 'react';
import { ProviderAirtableData, ProviderMongoDBData } from 'interfaces';

interface ProviderContextProps {
  providerMongoDBData: ProviderMongoDBData;
  providerAirtableData: ProviderAirtableData;
}
const ProviderContext = createContext<ProviderContextProps>(
  {} as ProviderContextProps
);

export const useProviderContext = () => useContext(ProviderContext);

export const ProviderContextProvider: React.FC<{
  children: React.ReactNode;
  value: ProviderContextProps;
}> = ({ children, value }) => (
  <ProviderContext.Provider value={value}>{children}</ProviderContext.Provider>
);
