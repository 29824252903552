import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Result, Button, message } from 'antd';
import { HttpStatusCode } from 'axios';
import Loading from 'components/Loading/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { getGoogleUserInfo, sendAccessTokenCode } from 'services/api';
import { useAuth } from 'services/auth';

function Login() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const userLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const response = await sendAccessTokenCode(code);
      if (response.status === HttpStatusCode.Ok) {
        const user = response.data;
        login(user);
        navigate(location.state?.from?.pathname || '/');
      } else {
        message.error('Login Failed');
      }
    },
    onError: (error) => message.error('Login Failed: ' + error),
    flow: 'auth-code',
  });

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await getGoogleUserInfo();
        if (response.status === HttpStatusCode.Ok) {
          const user = response.data;
          login(user);
          navigate(location.state?.from?.pathname || '/');
          return;
        } else {
          throw new Error('Login Failed');
        }
      } catch (error) {
        // message.error('Login Failed');
      }
      setIsLoading(false);
    };
    getUser();
  }, []);

  return (
    <div style={{ marginTop: '100px' }}>
      {isLoading ? (
        <Loading />
      ) : (
        <Result
          status="403"
          subTitle="Sorry, you are not authorized to access this website."
          extra={
            <Button onClick={() => userLogin()} type="primary">
              Sign in with Google 🚀
            </Button>
          }
        />
      )}
    </div>
  );
}

export default Login;
