import { countryFieldFilters } from 'constants/filters';
import { statusFieldFilters } from 'constants/filters';
import React, { FC, useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Tag, Button, Input, Space, ConfigProvider } from 'antd';
import type { GetRef, TableColumnType } from 'antd';
import type { TableProps } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import './NewSignUps.css';
import { HttpStatusCode } from 'axios';
import { ProviderSignUp } from 'interfaces';
import { useNavigate } from 'react-router-dom';
import { fetchNewSignUps } from 'services/api';

type ColumnsType<T extends object> = TableProps<T>['columns'];
type InputRef = GetRef<typeof Input>;

type DataIndex = keyof ProviderSignUp;
const NewSignUps: FC = () => {
  const [signUps, setSignUps] = useState<ProviderSignUp[]>([]);
  const searchInput = useRef<InputRef>(null);
  const navigate = useNavigate();
  function handleRowClick(record: ProviderSignUp) {
    // Stop onClick if selecting text
    if (window.getSelection()?.toString()) return;
    if (record.status === 'approved' || record.status === 'rejected') {
      return;
    }
    navigate(`/signups/${record.providerId}`);
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm']
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchNewSignUps();
        if (response.status === HttpStatusCode.Ok) {
          setSignUps(response.data);
        } else {
          throw new Error('Error fetching new sign-ups');
        }
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const getColumnSearchProps = (
    title: string,
    dataIndex: DataIndex
  ): TableColumnType<ProviderSignUp> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys as string[], confirm);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return (
          record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()) || false
        );
      }
      return false;
    },
  });

  const getAlternateDate = (date: Date, record: ProviderSignUp) => {
    let dateChoice = date;
    if (!date) {
      dateChoice = record.createdAtTimestamp;
    }
    return `${new Date(dateChoice).toLocaleString(undefined, { timeZoneName: 'short' })}`;
  };
  const columns: ColumnsType<ProviderSignUp> = [
    {
      title: 'Therapist Name',
      dataIndex: 'fullName',
      key: 'fullName',
      ...getColumnSearchProps('Therapist Name', 'fullName'),
    },
    {
      title: 'Signup Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date, record) => getAlternateDate(date, record),
      sorter: (a, b) =>
        new Date(a.createdAt || a.createdAtTimestamp).getTime() -
        new Date(b.createdAt || b.createdAtTimestamp).getTime(),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getColumnSearchProps('Phone Number', 'phoneNumber'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('Email', 'email'),
    },
    {
      title: 'Regions',
      dataIndex: 'states',
      key: 'states',
      render: (state: string[]) => <>{state?.join(', ')}</>,
    },
    {
      title: 'Country',
      dataIndex: 'countryName',
      key: 'countryName',
      filters: countryFieldFilters,
      onFilter: (value, record) => record.countryName === value,
    },
    {
      title: 'Red Flags',
      key: 'redFlags',
      dataIndex: 'redFlags',
      render: (score: number) => (
        <Tag
          color={score === 0 ? 'green' : `rgb(${255 - score * 10}, 0, 0)`}
          key={score}
        >
          {score}
        </Tag>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: string) => (
        <Tag
          color={
            status === 'approved'
              ? 'green'
              : status === 'rejected'
                ? 'red'
                : status === 'not started'
                  ? 'orange'
                  : 'geekblue'
          }
          key={status}
        >
          {status}
        </Tag>
      ),
      filters: statusFieldFilters,
      onFilter: (value, record) => record.status === value,
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            rowHoverBg: '#e6f4ff',
          },
        },
      }}
    >
      <Table
        rowKey={(record) => record.providerId}
        columns={columns}
        pagination={{
          position: ['bottomCenter'],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultPageSize: 10,
        }}
        // rowHoverBg={'#e6f4ff'}
        dataSource={signUps}
        onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
        loading={!signUps.length}
      />
    </ConfigProvider>
  );
};
export default NewSignUps;
