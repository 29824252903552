import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'services/auth';

const PrivateRoute: React.FC<{ Component: React.FC }> = ({ Component }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  return isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};
export default PrivateRoute;
