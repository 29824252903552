import React, { FC, useState } from 'react';
import './SearchComponent.css';
import { Empty, Input } from 'antd';
import { HttpStatusCode } from 'axios';
import { DifferencesData, NpiUserData } from 'interfaces';
import { fetchNpiData } from 'services/api';
import DifferencesComponent from '../DifferencesComponent';

const { Search } = Input;

enum boxStatus {
  normal = '',
  error = 'error',
}

const SearchComponent: FC = () => {
  const [searchData, setSearchData] = useState<DifferencesData | null>(null);
  const [npiCode, setNpiCode] = useState('');
  const [searchBoxStatus, setSearchBoxStatus] = useState<boxStatus>(
    boxStatus.normal
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDataMissing, setIsDataMissing] = useState<boolean>(false);
  const [npiResponseExists, setNpiResponseExists] = useState<boolean>(true);
  const [airtableResponseExists, setAirtableResponseExists] =
    useState<boolean>(true);

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      if (!npiCode) {
        setSearchBoxStatus(boxStatus.error);
        setError('Please enter a NPI code.');
        setIsLoading(false);
        return;
      }
      setError(null);
      setSearchBoxStatus(boxStatus.normal);
      setIsDataMissing(false); //TODO: refactor
      setNpiResponseExists(true);
      setAirtableResponseExists(true);
      setSearchData(null);
      const npiResponse: { status: HttpStatusCode; data: NpiUserData } =
        await fetchNpiData('npi', npiCode);
      const airtableResponse: { status: HttpStatusCode; data: NpiUserData } =
        await fetchNpiData('airtable', npiCode);

      if (
        npiResponse.status === HttpStatusCode.Ok &&
        airtableResponse.status === HttpStatusCode.Ok
      ) {
        const searchData = {
          npiData: npiResponse.data,
          airtableData: airtableResponse.data,
        };
        setSearchData(searchData);
        setIsLoading(false);
      }
      if (!npiResponse.data) {
        setIsLoading(false);
        setIsDataMissing(true);
        setNpiResponseExists(false);
      }
      if (!airtableResponse.data) {
        setIsLoading(false);
        setIsDataMissing(true);
        setAirtableResponseExists(false);
      }
    } catch (error) {
      console.error('Error during search:', error);
      setError('An error occurred during the search. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <h2 className="text">Search for NPI</h2>
        <Search
          // allowClear
          // style={{ width: "15vw", minWidth: "100px"}}
          className="search-container"
          onSearch={handleSearch}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setNpiCode(e.target.value)
          }
          placeholder="Enter NPI"
          enterButton
          size="large"
          loading={isLoading}
          status={searchBoxStatus}
        />
        {error && <div className="error">{error}</div>}
        {isDataMissing && (
          <Empty
            description={
              <div className="error">
                <p>Data is missing for comparison.</p>
                <ul>
                  {!npiResponseExists && <li>NPI Datasette DB</li>}
                  {!airtableResponseExists && <li>AirTable</li>}
                </ul>
              </div>
            }
          />
        )}
      </div>
      {searchData && <DifferencesComponent data={searchData} />}
    </>
  );
};

export default SearchComponent;
