import React, { useEffect, useState } from 'react';
import {
  Descriptions,
  DescriptionsProps,
  Empty,
  Select,
  Typography,
} from 'antd';
import { HttpStatusCode } from 'axios';
import Loading from 'components/Loading/Loading';
import { DifferencesData } from 'interfaces';
import { useFormContext } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { fetchNpiData } from 'services/api';
import DifferencesComponent from '../../DifferencesComponent';
import { useProviderContext } from '../ProviderContext';

const { Title, Paragraph } = Typography;

const Step4: React.FC = () => {
  const { control } = useFormContext();
  const { providerAirtableData } = useProviderContext();
  const [hasProviderNpi, setHasProviderNpi] = useState<boolean>();
  const [searchData, setSearchData] = useState<DifferencesData>();
  const [airtableHasNpi, setAirtableHasNpi] = useState<boolean>(true);
  const [datasetteHasNpi, setDatasetteHasNpi] = useState<boolean>(true);

  const providerNPIItems: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Provider NPI',
      labelStyle: { width: '20vw' },
      contentStyle: { width: '20vw' },
      children: <>{providerAirtableData.providerNpi}</>,
    },
  ];

  useEffect(() => {
    const getNpiDifferencesData = async () => {
      try {
        const providerNpi = providerAirtableData?.providerNpi?.toString();
        if (!providerNpi) {
          setHasProviderNpi(false);
          return;
        }
        setHasProviderNpi(true);
        const npiResponse = await fetchNpiData('npi', providerNpi);
        const airtableResponse = await fetchNpiData('airtable', providerNpi);
        if (npiResponse.status === HttpStatusCode.NotFound) {
          setDatasetteHasNpi(false);
        }
        if (airtableResponse.status === HttpStatusCode.NotFound) {
          setAirtableHasNpi(false);
        }
        if (
          npiResponse.status === HttpStatusCode.Ok &&
          airtableResponse.status === HttpStatusCode.Ok
        ) {
          setDatasetteHasNpi(true);
          setAirtableHasNpi(true);
          const searchData = {
            npiData: npiResponse.data,
            airtableData: airtableResponse.data,
          };
          setSearchData(searchData);
        }
      } catch (error) {
        console.error('Error fetching NPI Differences:', error);
      }
    };
    getNpiDifferencesData();
  }, []);

  const RenderDifferences: React.FC = () => {
    if (!searchData) {
      if (!hasProviderNpi) {
        return <Empty description={<h4>NPI Number is not provided</h4>} />;
      }
      if (!airtableHasNpi) {
        return (
          <Empty description={<h4>NPI Number is missing from Airtable</h4>} />
        );
      }
      if (!datasetteHasNpi) {
        return (
          <Empty description={<h4>NPI Number is missing from Datasette</h4>} />
        );
      }
      return <Loading />;
    }
    return (
      <>
        {hasProviderNpi ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Descriptions
              size="middle"
              bordered
              column={1}
              style={{
                width: '40vw',
                maxWidth: '400px',
                marginBottom: '10px',
              }}
              items={providerNPIItems}
            />
          </div>
        ) : null}

        <DifferencesComponent data={searchData} />
        <Typography>
          <Title level={4}>Tips</Title>
          {datasetteHasNpi ? (
            <Paragraph>
              <li>
                NPI Last update at date on Datasette:{' '}
                {searchData.npiData['Last Update Date']}
              </li>
            </Paragraph>
          ) : null}
          {hasProviderNpi ? (
            <Paragraph>
              <li>
                <a
                  href={`https://npiregistry.cms.hhs.gov/provider-view/${providerAirtableData.providerNpi}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  NPPES NPI Registry for NPI: {providerAirtableData.providerNpi}
                </a>
              </li>
            </Paragraph>
          ) : null}
          <Paragraph>
            <li>
              Data Matching: Cross-reference each provided detail against the
              NPI database, focusing on exact matches for names, specialties,
              and locations.
            </li>
          </Paragraph>

          <Paragraph>
            <li>
              Anomaly Identification: Be vigilant for any anomalies or
              irregularities between provided information and NPI data, such as
              mismatched addresses or practice details.
            </li>
          </Paragraph>
        </Typography>
      </>
    );
  };

  return (
    <>
      <RenderDifferences />
      <div className="center-step-actions">
        <FormItem
          name="stepFour"
          label={
            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
              How many data points are mismatching ?
            </p>
          }
          control={control}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select
            style={{ width: 80 }}
            options={[
              { value: 0, label: '0' },
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 5, label: '5' },
              { value: 6, label: '6' },
            ]}
          />
        </FormItem>
      </div>
    </>
  );
};

export default Step4;
